import React from "react";
import useWindowDimensions from "../../../utils/windowSize";

export default function ProcessInfoGraphic() {
  const { width } = useWindowDimensions();

  return (
    <div className="lidar__container">
      <Img1 />
      <Txt1 />

      {width && width >= 990 ? (
        <>
          <Img2 />
          <Txt2 />
        </>
      ) : (
        <>
          <Txt2 />
          <Img2 />
        </>
      )}

      <Img3 />
      <Txt3 />

      {width && width >= 990 ? (
        <>
          <div />
          <Img4 />
          <Txt4 />
        </>
      ) : (
        <>
          <Txt4 />
          <Img4 />
        </>
      )}

      <Img5 />
      <Txt5 />
    </div>
  );
}

const Img = ({ src, className = "" }: { src: string; className?: string }) => (
  <div className={`lidar__img-container ${className}`}>
    <img src={src} alt="" loading="lazy" />
  </div>
);
const Txt = ({
  title,
  text,
  children,
  className = "",
}: {
  title: string;
  text: string;
  children?: React.ReactNode;
  className?: string;
}) => (
  <div className={`lidar__text-container ${className}`}>
    <h3>{title}</h3>
    {text ? <p>{text}</p> : null}
    {children}
  </div>
);

const Img1 = () => (
  <Img
    src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F1.svg?alt=media&token=8ade4d4f-2816-42aa-9121-6c6835c79810"
    className="one"
  />
);
const Txt1 = () => (
  <Txt
    title="Data Acquisition"
    text="Assets are documented with mobile, web and 3D laser scanning applications"
    className="one"
  />
);
const Img2 = () => (
  <Img
    src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F2.svg?alt=media&token=6531dbf5-90a2-4587-a80b-30a0fae19583"
    className="two"
  />
);
const Txt2 = () => (
  <Txt
    title="Data Processing"
    text="Site data is processed using 3D reality capture softwares."
    className="two"
  />
);
const Img3 = () => (
  <Img
    src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F3.svg?alt=media&token=15233744-53d7-4d03-9d29-8a8626e9005b"
    className="three"
  />
);
const Txt3 = () => (
  <Txt
    title="BIM"
    text="Reality data is traced in design softwares such as Revit, AutoCAD, or Sketchup."
    className="three"
  />
);
const Img4 = () => (
  <Img
    src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F4.svg?alt=media&token=f6a8e040-22fa-4af4-bc1a-128126f0b3d4"
    className="four"
  />
);
const Txt4 = () => (
  <Txt
    title="Any File"
    text="We deliver any file type and connect to existing cloud infrastructures."
    className="four"
  />
);
const Img5 = () => (
  <Img
    src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F5.svg?alt=media&token=f146c5de-b859-4d61-9753-d462620f2f10"
    className="five"
  />
);
const Txt5 = () => (
  <Txt title="Integration" text="" className="five">
    <ul>
      <li>Point Clouds</li>
      <li>CAD Drawings</li>
      <li>3D Revit Models</li>
      <li>Reality Analysis</li>
      <li>Robotic Imaging API</li>
    </ul>
  </Txt>
);
