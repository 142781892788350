import React from "react";
import { Link } from "gatsby";
import { logAnalytics } from "../firebase";

export default function OrderButton({
  className,
  title,
  to,
}: {
  className?: string;
  title?: string;
  to?: string;
}) {
  return (
    <Link
      className={`btn btn--link orderButtons ${className}`}
      to={`${to}`}
      onClick={() => logAnalytics("clicked_receive_quote")}
    >
      {/* These spans are for animation purposes. See OrderButton.scss for specifics */}
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span className="text">{title}</span>
    </Link>
  );
}
